import React from 'react';
import './Contactus.css';
import location from '../../assets/images/location.png';
import envelop from '../../assets/images/envelop.png';
import mobilebutton from '../../assets/images/mobilebutton.png';
function Contactus() {
	return (
		<div className="content-wrapper">
			<div className="heading">
				<h2 className="m-auto">Contact Us</h2>
			</div>
			<div className="contactuswrapper">
				<div className="containers">
					<div className="heading-contact text-center">
						<label className="m-auto">
							We’d Love To <strong>Help You</strong>
						</label>
					</div>
					<div className="consumer-top  consumer-middle flex justify-center gap-100">
						<div className="contactus">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d28022.302259901633!2d77.28035378398376!3d28.6061426207158!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1zS0hJQ0hSSVBVUiAgTUFJTiBST0FELE1BWVVSIFZJSEFSLCBQSEFTRSAyPGJyLz4gIAkJCQkJCQkJCQlORVcgREVMSEksRUFTVCBERUxISSAsREVMSEktIDExMDA5MSA!5e0!3m2!1sen!2sin!4v1698306326333!5m2!1sen!2sin"
								width="100%"
								height="100%"
								allowfullscreen=""
								loading="lazy"
								referrerpolicy="no-referrer-when-downgrade"
								title="map"
							></iframe>
							<div className="contactusdetail">
								<div className="contactaddress">
									<div className="address">
										<label>Registered Address</label>
									</div>
									<div className="address">
										<img src={location} alt="location" />
										<p>
											PLOT NO.597,KHICHRIPUR MAIN ROAD,MAYUR VIHAR, PHASE 2<br />
											NEW DELHI,EAST DELHI ,DELHI- 110091{' '}
										</p>
									</div>
								</div>
								<div className="contactaddress">
									<div className="address">
										<label>Contact Us</label>
									</div>
									<div className="address">
										<img src={envelop} alt="envelop" />
										<p>
											<strong>Email Id :</strong>contactus.neocyberbertehnology@aol.com
										</p>
									</div>
									<div className="address">
										<img src={mobilebutton} alt="mobilebutton" />
										<p>
											<strong>Phone No :</strong>7900547061
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Contactus;
